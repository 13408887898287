<template>
  <div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            @click="abrirModalPaquete('Agregar', barcaza.id)"
            class="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#Modal_paquetes"
            v-if="$store.getters.can('admin.barcazas.asignPaquetes')"
          >
            <i class="far fa-folder"></i> Paquetes
          </button>
        </div>
      </div>
      <hr />
      <div class="row">
        <div
          class="col-md-12"
          v-for="paquete in barcaza.paquetes"
          :key="paquete.id"
        >
          <div class="card card-warning card-outline">
            <div class="card-header pt-2 pb-2">
              <h3 class="card-title">
                <i class="far fa-folder"></i>
                {{ paquete.nombre }}
              </h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body" style="display: block">
              <div class="row">
                <div class="col-md-12">
                  <table
                    id="docs"
                    class="
                      table table-bordered table-hover table-sm
                      text-center
                    "
                  >
                    <thead>
                      <tr>
                        <th>Documento</th>
                        <th>Referencia</th>
                        <th>Emisor</th>
                        <th>Fecha Emisión</th>
                        <th>Fecha Vencimiento</th>
                        <th
                          v-if="$store.getters.can('admin.barcazas.checkDoc')"
                        >
                          Verificar
                        </th>
                        <th colspan="3">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="documento in paquete.documentos"
                        :key="documento.id"
                        v-bind:class="
                          documento.pivot.relevante &&
                          !validaDoc(barcaza.documentos_files, documento, 2)
                            ? 'alert-default-danger'
                            : 'alert-default-success'
                        "
                      >
                        <td v-text="documento.descripcion"></td>
                        <td>
                          <p
                            v-if="
                              validaDoc(barcaza.documentos_files, documento, 1)
                            "
                          >
                            {{
                              barcaza.documentos_files.find(
                                (file) => file.documento_id === documento.id
                              ).referencia
                            }}
                          </p>
                        </td>
                        <td>
                          <p
                            v-if="
                              validaDoc(barcaza.documentos_files, documento, 1)
                            "
                          >
                            {{
                              barcaza.documentos_files.find(
                                (file) => file.documento_id === documento.id
                              ).emisor
                            }}
                          </p>
                        </td>
                        <td>
                          <p
                            v-if="
                              validaDoc(barcaza.documentos_files, documento, 1)
                            "
                          >
                            {{
                              barcaza.documentos_files.find(
                                (file) => file.documento_id === documento.id
                              ).fecha_emision
                            }}
                          </p>
                        </td>
                        <td>
                          <p
                            v-if="
                              validaDoc(barcaza.documentos_files, documento, 1)
                            "
                          >
                            {{
                              barcaza.documentos_files.find(
                                (file) => file.documento_id === documento.id
                              ).fecha_vencimiento
                            }}
                          </p>
                        </td>
                        <td
                          v-if="$store.getters.can('admin.barcazas.checkDoc')"
                        >
                          <div
                            class="form-group"
                            v-if="
                              validaDoc(barcaza.documentos_files, documento, 2)
                            "
                          >
                            <div
                              class="
                                custom-control
                                custom-switch
                                custom-switch-off-danger
                                custom-switch-on-success
                              "
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="
                                  'customSwitch' +
                                    paquete.id +
                                    '-' +
                                    documento.id
                                "
                                :class="'customSwitch' + documento.id"
                                v-model="
                                  btnVerificado[paquete.id + '-' + documento.id]
                                "
                                v-if="
                                  $store.getters.can(
                                    'admin.barcazas.verificarDocumento'
                                  )
                                "
                                @change="
                                  verificarDoc(
                                    documento.id,
                                    barcaza.documentos_files.find(
                                      (file) =>
                                        file.documento_id === documento.id
                                    ).id,
                                    paquete.id
                                  )
                                "
                              />
                              <label
                                class="custom-control-label"
                                :for="
                                  'customSwitch' +
                                    paquete.id +
                                    '-' +
                                    documento.id
                                "
                              ></label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-info btn-sm"
                            :disabled="
                              validaDoc(barcaza.documentos_files, documento, 2)
                            "
                            data-toggle="modal"
                            data-target="#Modal_docs"
                            @click="abrirModalDoc('Agregar', documento)"
                            v-if="
                              $store.getters.can(
                                'admin.barcazas.documentosEdit'
                              )
                            "
                          >
                            <i class="fa fa-upload"></i>
                          </button>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            class="btn btn-primary btn-sm"
                            :href="
                              uri_docs +
                                barcaza.documentos_files.find(
                                  (file) => file.documento_id === documento.id
                                ).link
                            "
                            v-if="
                              validaDoc(barcaza.documentos_files, documento, 2)
                            "
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            v-if="
                              validaDoc(
                                barcaza.documentos_files,
                                documento,
                                1
                              ) &&
                                $store.getters.can(
                                  'admin.barcazas.documentosEdit'
                                )
                            "
                            @click="
                              eliminarDoc(
                                barcaza.documentos_files.find(
                                  (file) => file.documento_id === documento.id
                                ).id
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL PAQUETES -->
    <div
      class="modal fade"
      id="Modal_paquetes"
      v-if="$store.getters.can('admin.barcazas.asignPaquetes')"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-11">
                <div class="form-group">
                  <label for="paquete">Paquete</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="formPaquete.listPaquetes"
                    label="nombre"
                    :options="listPaquetes"
                    placeholder="Paquetes"
                    @input="getSelectPaquete()"
                  ></v-select>
                </div>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  class="btn btn-primary mt-4"
                  @click="savePaquete(true, null)"
                >
                  <i class="fas fa-save"></i>
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <table
                class="table table-stripped table-hover table-bordered table-sm"
              >
                <thead>
                  <tr>
                    <th>Paquete</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="paquete in barcaza.paquetes" :key="paquete.id">
                    <td v-text="paquete.nombre"></td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="savePaquete(false, paquete.id)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer justify-content-between"></div>
        </div>
      </div>
    </div>

    <!-- MODAL DOCUMENTOS -->
    <div class="modal fade" id="Modal_docs">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="descripcion">Descripción</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="descripcion"
                    placeholder="descripcion"
                    v-model="formDoc.descripcion"
                    :class="
                      $v.formDoc.descripcion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="referencia">Referencia</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="referencia"
                    placeholder="Referencia"
                    v-model="formDoc.referencia"
                    :class="
                      $v.formDoc.referencia.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="emisor">Emisor</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="emisor"
                    placeholder="Emisor"
                    v-model="formDoc.emisor"
                    :class="
                      $v.formDoc.emisor.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="fecha_emision">Fecha Emisión</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_emision"
                    placeholder="Fecha Emisión"
                    v-model="formDoc.fecha_emision"
                    :class="
                      $v.formDoc.fecha_emision.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-md-6 col-sm-12"
                v-if="formDoc.vencimiento"
              >
                <div class="form-group">
                  <label for="fecha_vencimiento">Fecha Vencimiento</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_vencimiento"
                    placeholder="Fecha Emisión"
                    v-model="formDoc.fecha_vencimiento"
                    :class="
                      $v.formDoc.fecha_vencimiento.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <input
                  type="file"
                  class="form-control-file"
                  ref="file"
                  id="btnFile"
                  @change="getFile"
                  accept="application/msword,application/pdf,application/rtf,image/*"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-primary" @click="saveDoc()">
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "BarcazaDocumento",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      barcaza: [],
      formDoc: {},
      formPaquete: {},
      listPaquetes: [],
      btnVerificado: {},
      modal: {
        title: "",
        accion: "",
      },
      fileDoc: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    if (this.formDoc.vencimiento) {
      return {
        formDoc: {
          descripcion: {
            required,
          },
          referencia: {
            required,
          },
          emisor: {
            required,
          },
          fecha_emision: {
            required,
          },
          fecha_vencimiento: {
            required,
          },
        },
        formPaquete: {
          paquete_id: {
            required,
          },
        },
      };
    } else {
      return {
        formDoc: {
          descripcion: {
            required,
          },
          referencia: {
            required,
          },
          emisor: {
            required,
          },
          fecha_emision: {
            required,
          },
        },
        formPaquete: {
          paquete_id: {
            required,
          },
        },
      };
    }
  },
  methods: {
    getPaquetes() {
      axios.get("/api/admin/paquetes/lista").then((response) => {
        this.listPaquetes = response.data;
      });
    },
    getSelectPaquete() {
      this.formPaquete.paquete_id = this.formPaquete.listPaquetes.id;
    },
    getVehiculo(id) {
      axios.get("/api/admin/barcazas/" + id).then((response) => {
        this.barcaza = response.data[0];
        this.getVerificado();
      });
    },

    abrirModalPaquete(accion, id) {
      this.modal.accion = accion;
      this.modal.title = accion + " Paquete";
      this.formPaquete.barcaza_id = id;
    },

    validaDoc(files, documento, tipo) {
      if (
        files.find((file) => file.documento_id === documento.id) !== undefined
      ) {
        if (tipo == 2) {
          if (
            files.find((file) => file.documento_id === documento.id).link !== ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    savePaquete(accion, id) {
      if (id != null) {
        this.formPaquete.paquete_id = id;
      }

      this.formPaquete.barcaza_id = this.barcaza.id;
      this.formPaquete.accion = accion;
      if (!this.$v.formPaquete.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "PUT",
          url: "/api/admin/barcazas/assignOrRevokePaquetesDocumentos",
          data: this.formPaquete,
        })
          .then(() => {
            this.getVehiculo(this.$parent.form.id);
            this.$refs.closeModal.click();
            this.formPaquete = {};
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          });
      }
    },

    abrirModalDoc(accion, documento) {
      this.modal.accion = accion;
      this.modal.title = accion + " Documento";
      this.formDoc = {
        entidad_id: this.$parent.form.id,
        documento_id: documento.id,
        descripcion: documento.descripcion,
        vencimiento: documento.vencimiento,
      };
    },

    getFile(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileDoc = file;
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    saveDoc() {
      if (!this.$v.formDoc.$invalid) {
        this.$parent.cargando = true;
        let formData = new FormData();
        formData.append("entidad_id", this.formDoc.entidad_id);
        formData.append("entidad", "Barcaza");
        formData.append("documento_id", this.formDoc.documento_id);
        formData.append("descripcion", this.formDoc.descripcion);

        formData.append("referencia", this.formDoc.referencia);
        formData.append("emisor", this.formDoc.emisor);
        formData.append("fecha_emision", this.formDoc.fecha_emision);
        formData.append("fecha_vencimiento", this.formDoc.fecha_vencimiento);
        formData.append("file", this.fileDoc);

        axios
          .post("/api/admin/Barcazas/documentos", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.msg) {
              this.$swal({
                icon: "error",
                title: res.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            } else {
              this.getVehiculo(this.$parent.form.id);
              this.$refs.closeModal.click();
              this.formDoc = {};
              this.$swal({
                icon: "success",
                title: "Se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            }
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Ocurrio un error, vuelva a intentarlo...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    eliminarDoc(idDoc) {
      this.$swal({
        title: "Esta seguro de eliminar esta Documento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          let datos = {
            entidad_id: this.$parent.form.id,
            entidad: "Barcaza",
          };
          axios({
            method: "DELETE",
            url: "/api/admin/barcazas/documentos/" + idDoc,
            data: datos,
          }).then(() => {
            this.getVehiculo(this.$parent.form.id);
            this.$swal({
              icon: "success",
              title: "Se elimino la Documento exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
    verificarDoc(documentoId, documentoFilId, paqueteId) {
      let data = {
        verificado: this.btnVerificado[paqueteId + "-" + documentoId] ? 1 : 0,
      };
      axios({
        method: "PUT",
        url: "/api/admin/barcazas/checkDoc/" + documentoFilId,
        data: data,
      }).then(() => {
        let check = document.getElementsByClassName(
          "customSwitch" + documentoId
        );
        for (let i = 0; i < check.length; i++) {
          if (data.verificado == 1) {
            check[i].checked = true;
          } else {
            check[i].checked = false;
          }
        }
        this.$swal({
          icon: "success",
          title: "Documento verificado...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      });
    },
    getVerificado() {
      for (let i = 0; i < this.barcaza.paquetes.length; i++) {
        const paquete = this.barcaza.paquetes[i];
        for (let j = 0; j < paquete.documentos.length; j++) {
          const documento = paquete.documentos[j];
          let verif = this.barcaza.documentos_files.find(
            (file) => file.documento_id === documento.id
          );

          if (verif !== undefined) {
            this.btnVerificado[
              paquete.id + "-" + documento.id
            ] = this.barcaza.documentos_files.find(
              (file) => file.documento_id === documento.id
            ).verificado;
          } else {
            this.btnVerificado[paquete.id + "-" + documento.id] = false;
          }
        }
      }
    },
  },
  mounted() {
    this.getPaquetes();
    this.getVehiculo(this.$parent.form.id);
  },
};
</script>
